<template>
  <div class="fileItemBox" @click.stop="downloadFile">
    <div class="fileIcon">
      <van-image
        width="100%"
        height="100%"
        fit="fill"
        :src="iconSrc"
        />
    </div>
    <div class="fileName">
      {{fileInfo.name}}
    </div>
  </div>
</template>
<script>
import { env } from "@/utils";
import { formartImageUrl } from "@/utils/help";
export default {
  props: {
    // 文件的基本信息
    fileInfo: {
      type:Object,
      default:() => {
        return {}
      }
    },
    // 是否需要展示删除按钮
    needDelete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconSrc() {
      if( this.judgment(this.fileInfo.name,'txt') ){
        return require('../../../../assets/images/newUserInfo/text.png')
      } else if(this.judgment(this.fileInfo.name,'doc') || this.judgment(this.fileInfo.name,'docx')){
        return require('../../../../assets/images/newUserInfo/word.png')
      } else if(this.judgment(this.fileInfo.name,'pdf')){
        return require('../../../../assets/images/newUserInfo/pdf.png')
      } else if(this.judgment(this.fileInfo.name,'xlsx') || this.judgment(this.fileInfo.name,'xls')){
        return require('../../../../assets/images/newUserInfo/excel.png')
      } else if(this.judgment(this.fileInfo.name,'pptx') || this.judgment(this.fileInfo.name,'ppt')){
        return require('../../../../assets/images/newUserInfo/ppt.png')
      } else if(
        this.judgment(this.fileInfo.name,'tar') || 
        this.judgment(this.fileInfo.name,'7z') ||
        this.judgment(this.fileInfo.name,'bz2') ||
        this.judgment(this.fileInfo.name,'gz') ||
        this.judgment(this.fileInfo.name,'wim') ||
        this.judgment(this.fileInfo.name,'xz') ||
        this.judgment(this.fileInfo.name,'zip')
      ){
        return require('../../../../assets/images/newUserInfo/compressed.png')
      } else {
        return require('../../../../assets/images/newUserInfo/unknown.png')
      }
    }
  },
  methods: {
    // 判断是否是这个文件
    judgment(text,type) {
      let typeLength =text.length - text.lastIndexOf(type);
      return text.lastIndexOf(type) != -1 && typeLength == type.length
    },
    // 下载文件
    downloadFile() { 
      let downloadFileUrl = `${env( "BASEURL" )}/rest/user/download?downloadUrl=${formartImageUrl(this.fileInfo.path)}&fileName=${this.fileInfo.name.replace(/<\/?.+?\/?>/g,"")}`;
      window.open(downloadFileUrl,"_blank");
    }
  }
}
</script>
<style lang="stylus" scoped>
  .fileItemBox
    width 100%;
    background #F4F5F7;
    padding 15px;
    display flex;
    align-items center;
    justify-content flex-start;
    box-sizing border-box;
    .fileIcon
      height 66px;
      width 61px;
      flex-shrink 0;
    .fileName
      height 66px;
      flex 1;
      display flex;
      align-items center;
      justify-content flex-start;
      box-sizing border-box;
      padding 0 17px;
      font-size 16px;
      color #333333;
      overflow hidden;

</style>