import { render, staticRenderFns } from "./fileItem.vue?vue&type=template&id=12bce5ab&scoped=true&"
import script from "./fileItem.vue?vue&type=script&lang=js&"
export * from "./fileItem.vue?vue&type=script&lang=js&"
import style0 from "./fileItem.vue?vue&type=style&index=0&id=12bce5ab&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12bce5ab",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\h5\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('12bce5ab')) {
      api.createRecord('12bce5ab', component.options)
    } else {
      api.reload('12bce5ab', component.options)
    }
    module.hot.accept("./fileItem.vue?vue&type=template&id=12bce5ab&scoped=true&", function () {
      api.rerender('12bce5ab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/business/newVersion/common/fileItem.vue"
export default component.exports