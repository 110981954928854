<template>
  <div class="displayComponentItem">
    <div class="textItem" v-if="item.key === 'text'" v-html="item.content.replace(/\n/g, '<br>').replace(/&nbsp;/ig, ' ')"></div>
    <div class="urlItem" v-if="item.key === 'url'">
      <div class="urlIcon">
        <i class="iconfont_Me icon_link iconStyle"></i>
      </div>
      <a @click.stop="()=>{return;}" class="urlText" target='_blank' :href="item.content.jumpLink || item.content.showLink">
        {{item.content.showLink}}
      </a>
    </div>
    <a class="webItem" v-if="item.key === 'web'" @click.stop="()=>{return;}" target='_blank' :href="item.content.url || ''">
      <div class="webIcon">
        <van-image
          width="100%"
          height="100%"
          fit="cover"
          :src="item.content.image"
        >
          <template v-slot:error>
            <div class="webError">
              Melinked
            </div>
          </template>
        </van-image>
      </div>
      <div class="webInfo">
        <div class="webTitle">
          {{item.content.title}}
        </div>
        <div class="webContent">
          {{item.content.content}}
        </div>
      </div>
    </a>
    <div class="titleItem" v-if="item.key === 'title'">
      {{handleNBSP(item.content)}}
    </div>
    <div class="imageItem" v-if="item.key === 'image'">
      <van-image
        width="100%"
        height="100%"
        fit="cover"
        :src="formartImageUrl(item.content)"
        />
    </div>
    <div class="videoItem" v-if="item.key === 'video'">
      <video
        class="videoStyle"
        controls="controls"
        type="video/mp4"
        webkit-playsinline="true"
        playsinline="true"
        x-webkit-airplay="allow"
        x5-video-player-type="h5"
        x5-video-orientation="portraint"
        x5-playsinline="true"
        x5-video-player-fullscreen="true"
        :src="item.content.indexOf('?vframe/jpg/offset/0') !== -1 ?item.content.split('?')[0]: item.content"></video>
    </div>
    <div class="fileItem" v-if="item.key === 'file'">
      <FileItem :fileInfo="{path:item.content,name:item.fileName}"></FileItem>
    </div>
  </div>
</template>
<script>
import FileItem from "./fileItem";
import { formartImageUrl } from "@/utils/help";
export default {
  components: {
    FileItem
  },
  props: {
    item: {
      type:Object,
      default: ()=>{
        return {}
      }
    }
  },
  methods: {
    formartImageUrl,
    // 在新窗口打开页面
    openWebInNewWindow(url) {
      window.open(url)
    },
    // 处理文本中的nbsp;
    handleNBSP(content) {
      return content.replace(/&nbsp;/ig, ' ');
    }
  }
}
</script>
<style lang="stylus" scoped>
  .displayComponentItem
    width 100%;
    height 100%;
    overflow hidden;
    padding-top 1px;
    .textItem
      font-size 16px;
      color #666666;
      line-height 26px;
      margin 5px 0;
      white-space pre-wrap;
    .urlItem
      height 22px;
      width 100%;
      margin 5px 0;
      display flex;
      align-items center;
      justify-content flex-start;
      .urlIcon
        width 16px;
        height 16px;
        flex-shrink 0;
        display flex;
        align-items center;
        justify-content center;
        margin-right 5px;
        .iconStyle
          font-size 16px;
          color #0D439F;
      .urlText
        flex 1;
        min-width 0;
        text-decoration none;
        line-height 22px;
        font-size 16px;
        color #0D439F;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    .webItem
      width 100%;
      height 96px;
      margin 5px 0;
      background #F4F5F7;
      display flex;
      align-items center;
      justify-content flex-start;
      box-sizing border-box;
      padding 0 10px;
      .webIcon
        width 79px;
        height 79px;
        flex-shrink 0;
        overflow hidden;
        .webError
          width 100%;
          height 100%;
          overflow hidden;
          background #33CC66;
          display flex;
          align-items center;
          justify-content center;
          font-size 16px;
          font-weight bold;
          color #FFFFFF;
      .webInfo
        flex 1;
        min-width 0;
        height 79px;
        overflow hidden;
        box-sizing border-box;
        padding-left 10px;
        .webTitle
          line-height 24px;
          font-size 14px;
          font-weight bold;
          color #1A051D;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          margin-bottom 5px;
        .webContent
          line-height 20px;
          font-size 12px;
          color #1A051D;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
    .titleItem
      font-size 18px;
      color #200E32;
      line-height 38px;
      font-weight bold;
      margin 5px 0;
    .imageItem
      width 100%;
      margin 5px 0;
      border-radius 6px;
      height auto;
      overflow hidden;
    .videoItem
      height 214px;
      width 100%;
      margin 5px 0;
      border-radius 6px;
      overflow hidden;
      video
        height 100%;
        width 100%;
        background #999999;
        border-radius 6px;
    .fileItem
      width 100%;
      margin 5px 0;
      overflow hidden;

</style>