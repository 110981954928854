var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "displayComponentItem" }, [
    _vm.item.key === "text"
      ? _c("div", {
          staticClass: "textItem",
          domProps: {
            innerHTML: _vm._s(
              _vm.item.content.replace(/\n/g, "<br>").replace(/&nbsp;/gi, " ")
            )
          }
        })
      : _vm._e(),
    _vm.item.key === "url"
      ? _c("div", { staticClass: "urlItem" }, [
          _vm._m(0),
          _c(
            "a",
            {
              staticClass: "urlText",
              attrs: {
                target: "_blank",
                href: _vm.item.content.jumpLink || _vm.item.content.showLink
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return (function() {
                    return
                  })($event)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.item.content.showLink) + "\n    ")]
          )
        ])
      : _vm._e(),
    _vm.item.key === "web"
      ? _c(
          "a",
          {
            staticClass: "webItem",
            attrs: { target: "_blank", href: _vm.item.content.url || "" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return (function() {
                  return
                })($event)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "webIcon" },
              [
                _c("van-image", {
                  attrs: {
                    width: "100%",
                    height: "100%",
                    fit: "cover",
                    src: _vm.item.content.image
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "error",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "webError" }, [
                              _vm._v("\n            Melinked\n          ")
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2215666158
                  )
                })
              ],
              1
            ),
            _c("div", { staticClass: "webInfo" }, [
              _c("div", { staticClass: "webTitle" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.item.content.title) + "\n      "
                )
              ]),
              _c("div", { staticClass: "webContent" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.item.content.content) + "\n      "
                )
              ])
            ])
          ]
        )
      : _vm._e(),
    _vm.item.key === "title"
      ? _c("div", { staticClass: "titleItem" }, [
          _vm._v("\n    " + _vm._s(_vm.handleNBSP(_vm.item.content)) + "\n  ")
        ])
      : _vm._e(),
    _vm.item.key === "image"
      ? _c(
          "div",
          { staticClass: "imageItem" },
          [
            _c("van-image", {
              attrs: {
                width: "100%",
                height: "100%",
                fit: "cover",
                src: _vm.formartImageUrl(_vm.item.content)
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.item.key === "video"
      ? _c("div", { staticClass: "videoItem" }, [
          _c("video", {
            staticClass: "videoStyle",
            attrs: {
              controls: "controls",
              type: "video/mp4",
              "webkit-playsinline": "true",
              playsinline: "true",
              "x-webkit-airplay": "allow",
              "x5-video-player-type": "h5",
              "x5-video-orientation": "portraint",
              "x5-playsinline": "true",
              "x5-video-player-fullscreen": "true",
              src:
                _vm.item.content.indexOf("?vframe/jpg/offset/0") !== -1
                  ? _vm.item.content.split("?")[0]
                  : _vm.item.content
            }
          })
        ])
      : _vm._e(),
    _vm.item.key === "file"
      ? _c(
          "div",
          { staticClass: "fileItem" },
          [
            _c("FileItem", {
              attrs: {
                fileInfo: { path: _vm.item.content, name: _vm.item.fileName }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "urlIcon" }, [
      _c("i", { staticClass: "iconfont_Me icon_link iconStyle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }